import React, { useMemo } from 'react';
import { DatePickerDataHooks } from './dataHooks.const';
import { classes, st } from './DatePicker.st.css';
import { DatePicker as DatePickerComponent } from 'wix-ui-tpa/DatePicker';
import {
  DateAvailabilityStatus,
  DatePickerViewModel,
} from '../../ViewModel/datePickerViewModel/datePickerViewModel';
import { getLocalDateTimeStartOfDay } from '../../../../utils/dateAndTime/dateAndTime';
import { dateIndicationProps } from 'wix-ui-tpa/dist/src/components/DatePicker/DatePicker';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import { ReactComponent as DotIcon } from '../../../../assets/Icons/DotIndication.svg';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { rfcToShiftedDate } from '@wix/bookings-date-time';
import { TriggeredByOptions } from '../../../../types/types';

export type DatePickerProps = {
  datePickerViewModel: DatePickerViewModel;
};

const DotIndication: React.FC<{
  dateAvailabilityStatus?: DateAvailabilityStatus;
  shouldShow: boolean;
}> = (
  { dateAvailabilityStatus, shouldShow } = {
    dateAvailabilityStatus: undefined,
    shouldShow: false,
  },
) => {
  return (
    <div
      className={st(classes.dateIndicationContainer, {
        visible: shouldShow,
      })}
    >
      <DotIcon
        data-hook={DatePickerDataHooks.DOT_ICON}
        className={
          dateAvailabilityStatus === DateAvailabilityStatus.HAS_AVAILABLE_SLOTS
            ? classes.availableDateIndication
            : classes.notAvailableDateIndication
        }
      />
    </div>
  );
};

const DatePicker: React.FC<DatePickerProps> = ({ datePickerViewModel }) => {
  const {
    selectedDate: localDateTime,
    dateAvailabilityStatuses,
    firstDayOfTheWeek,
    todayLocalDateTime,
    locale,
    nextMonthLabel,
    prevMonthLabel,
  } = datePickerViewModel;
  const { onDateSelected, onMonthSelected } = useCalendarActions();
  const { isMobile } = useEnvironment();

  const onDateChange = async (date: Date) => {
    const localDateTimeStartOfDay = getLocalDateTimeStartOfDay(date);
    await onDateSelected(
      localDateTimeStartOfDay,
      TriggeredByOptions.DATE_SELECTED,
    );
  };

  const onMonthChange = async (date: Date) => {
    const localDateTimeStartOfDay = getLocalDateTimeStartOfDay(date);
    await onMonthSelected(localDateTimeStartOfDay);
  };

  const dateIndications = ({ date, isSelected }: dateIndicationProps) => {
    const dateAvailabilityStatus = dateAvailabilityStatuses.get(
      getLocalDateTimeStartOfDay(date),
    );
    return (
      <DotIndication
        dateAvailabilityStatus={dateAvailabilityStatus}
        shouldShow={!!dateAvailabilityStatus && !isSelected}
      />
    );
  };

  const value = useMemo(() => rfcToShiftedDate(localDateTime), [localDateTime]);
  const today = useMemo(
    () => rfcToShiftedDate(todayLocalDateTime),
    [todayLocalDateTime],
  );

  return (
    <DatePickerComponent
      data-hook={DatePickerDataHooks.DATE_PICKER}
      className={st(classes.root, { isMobile })}
      value={value}
      today={today}
      onChange={onDateChange}
      onMonthChange={onMonthChange}
      dateIndication={dateIndications}
      firstDayOfWeek={firstDayOfTheWeek}
      rightArrowAriaLabel={nextMonthLabel}
      leftArrowAriaLabel={prevMonthLabel}
      removeOuterPadding
      locale={locale}
    />
  );
};

export default DatePicker;
